<template>
    <div>
        <div class="account-pages mt-5 mb-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-5">
                        <div class="card">

                            <!-- Logo -->
                            <div class="card-header pt-4 pb-4 text-center bg-primary">
                                <a href="index.html">
                                    <span><img alt="" height="40" src="../../assets/images/logo-white.svg"></span>
                                </a>
                            </div>

                            <div class="card-body p-4">

                                <div class="text-center w-75 m-auto">
                                    <h4 class="text-dark-50 text-center mt-0 font-weight-bold">Update Password</h4>
                                    <p class="text-muted mb-4">Enter your password and confirm in order to update
                                        it.</p>
                                </div>

                                <form @submit.prevent="handleSubmit">

                                    <div class="form-group">
                                        <label for="password">Old Password</label>
                                        <div class="input-group input-group-merge">
                                            <input :class="{'is-invalid': submitted && errors.has('old_password')}"
                                                   class="form-control"
                                                   data-vv-as="Old Password"
                                                   id="old_password"
                                                   name="old_password"
                                                   placeholder="Enter your existing password"
                                                   type="password"
                                                   v-model="credentials.old_password"
                                                   v-validate="'required'">
                                            <div class="input-group-append" data-password="false"
                                                 style="cursor: pointer">
                                                <div class="input-group-text">
                                                    <span class="password-eye"></span>
                                                </div>
                                            </div>
                                            <div class="invalid-feedback"
                                                 v-if="submitted && errors.has('old_password')">{{
                                                errors.first('old_password') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="password">New Password</label>
                                        <div class="input-group input-group-merge">
                                            <input :class="{'is-invalid': submitted && errors.has('password')}"
                                                   class="form-control"
                                                   data-vv-as="Password"
                                                   id="password"
                                                   name="password"
                                                   placeholder="Enter your password"
                                                   ref="password"
                                                   type="password"
                                                   v-model="credentials.new_password"
                                                   v-validate="'required'">
                                            <div class="input-group-append" data-password="false"
                                                 style="cursor: pointer">
                                                <div class="input-group-text">
                                                    <span class="password-eye"></span>
                                                </div>
                                            </div>
                                            <div class="invalid-feedback" v-if="submitted && errors.has('password')">{{
                                                errors.first('password') }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="conf_password">Confirm Password</label>
                                        <div class="input-group input-group-merge">
                                            <input :class="{'is-invalid': submitted && errors.has('conf_password')}"
                                                   class="form-control"
                                                   data-vv-as="Confirm Password"
                                                   id="conf_password"
                                                   name="conf_password"
                                                   placeholder="Confirm Password"
                                                   type="password"
                                                   v-model="credentials.conf_password"
                                                   v-validate="'required|confirmed:password'">
                                            <div class="input-group-append" data-password="false"
                                                 style="cursor: pointer">
                                                <div class="input-group-text">
                                                    <span class="password-eye"></span>
                                                </div>
                                            </div>
                                            <div class="invalid-feedback"
                                                 v-if="submitted && errors.has('conf_password')">{{
                                                errors.first('conf_password') }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group mb-0 text-center">
                                        <button class="btn btn-primary" type="submit"> Submit</button>
                                    </div>

                                </form>
                            </div> <!-- end card-body -->
                        </div>
                        <!-- end card -->

                        <div class="row mt-3">
                            <div class="col-12 text-center">
                                <p class="text-muted">Return to Dashboard?
                                    <router-link :to="{name: 'dashboard'}" class="text-muted ml-1">
                                        <b>Click Here</b>
                                    </router-link>
                                </p>
                            </div> <!-- end col -->
                        </div>
                        <!-- end row -->

                    </div> <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end container -->
        </div>
        <footer class="footer footer-alt">
        </footer>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "ChangePassword",
        computed: {
            ...mapGetters(["currentUser"])
        },
        data() {
            return {
                submitted: false,
                credentials: {
                    old_password: '',
                    new_password: '',
                    conf_password: ''
                }
            };
        },
        mounted() {
            $("[data-password]").on('click', function () {
                if ($(this).attr('data-password') == "false") {
                    $(this).siblings("input").attr("type", "text");
                    $(this).attr('data-password', 'true');
                    $(this).addClass("show-password");
                } else {
                    $(this).siblings("input").attr("type", "password");
                    $(this).attr('data-password', 'false');
                    $(this).removeClass("show-password");
                }
            });
        },
        methods: {
            handleSubmit(e) {
                this.submitted = true;
                this.$validator.validate()
                    .then(valid => {
                        if (valid) {
                            this.updatePassword();
                        }
                    });
            },
            async updatePassword() {
                try {
                    this.credentials.email = this.currentUser.email;
                    await this.$http.post(`auth/changepassword`, this.credentials);
                    await this.$router.push({name: 'login'});
                    this.$toastr.s("Password successfully updated!", 'Success');
                } catch (e) {
                    if (e.response.status === 401) {
                        this.$toastr.e("You are not authorized!", "Error");
                    } else {
                        this.$toastr.e("Password updating failed!", 'Failed');
                    }
                }
            }
        }
    }
</script>

<style scoped>

</style>